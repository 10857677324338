import { IconChevronRight } from 'components/atoms'
import { trackEventCountly } from 'services/countly/countly'
import { CountlyEventNames } from 'services/countly/eventNames'
import Image from 'next/image'
import React from 'react'
import styles from 'styles/components/organisms/subProduct.module.scss'
import elementId from 'utils/helpers/trackerId'

export interface SubProductCardProps {
  url: string
  promoOrder: number
  alt: string
  imageSrc: any
  title: string
  description: string
  textPromoBanner: string
}
const SubProductCard = ({
  url,
  promoOrder,
  alt,
  imageSrc,
  title,
  description,
  textPromoBanner,
}: SubProductCardProps) => {
  const trackCountly = (promoUrl: string, promoOrder: number) => {
    trackEventCountly(CountlyEventNames.WEB_HOMEPAGE_LAYANAN_LAIN_CLICK, {
      PAGE_DIRECTION_URL: promoUrl,
      BANNER_ORDER: promoOrder,
    })
  }
  return (
    <div
      className={styles.bannerPromo}
      onClick={() => {
        trackCountly(url, promoOrder)
        window.open(url, '_blank')
      }}
      data-testid={elementId.Homepage.Button.LebihLanjut.Refi}
    >
      <Image
        alt={alt}
        src={imageSrc}
        className={styles.promoBannerSmall}
        height="196"
        loading="lazy"
      />
      <div>
        <h3 className={styles.textTitlePromoBanner}>{title}</h3>
        <p className={styles.textDescriptionBanner}>{description}</p>
        <div className={styles.textPromoBanner}>
          {textPromoBanner}{' '}
          <div className={styles.spacingChevronIcon}>
            <IconChevronRight width={16} height={16} color="#FFFFFF" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubProductCard
