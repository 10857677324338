import styles from '/styles/pages/index.module.scss'
import React, { startTransition, useContext, useEffect, useState } from 'react'
import { InView } from 'react-intersection-observer'
import { setTrackEventMoEngageWithoutValue } from 'services/moengage'
import { EventName } from 'services/moengage/type'
import { LeadsActionParam, PageOriginationName } from 'utils/types/tracker'
import { AlephArticleCategoryType, Article, CityOtrOption } from 'utils/types'
import { COMData, COMDataTracking } from 'utils/types/models'
import { getLocalStorage } from 'utils/handler/localStorage'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import getCurrentEnvironment from 'utils/handler/getCurrentEnvironment'
import { alephArticleCategoryList } from 'utils/config/articles.config'
import { countDaysDifference } from 'utils/handler/date'
import {
  CtaWidget,
  FooterMobile,
  HowToUse,
  PromoSection,
} from 'components/molecules'
import {
  LeadsFormTertiary,
  ArticleWidget,
  SearchWidget,
  MainHeroLP,
  SubProduct,
  TestimonyWidget,
  LpCarRecommendations,
  CarOfTheMonth,
  SearchWidgetSection,
  WebAnnouncementBox,
} from 'components/organisms'
import { getCity } from 'utils/hooks/useGetCity'
import { HomePageDataLocalContext } from 'pages'
import { getToken } from 'utils/handler/auth'
import { useRouter } from 'next/router'
import { multiCreditQualificationPageUrl } from 'utils/helpers/routes'
import { savePageBeforeLogin } from 'utils/handler/auth'
import Seo from 'components/atoms/seo'
import { defaultSeoImage } from 'utils/helpers/const'
import { LocalStorageKey, RemoteConfigKey, SessionStorageKey } from 'utils/enum'
import {
  trackEventCountly,
  valueForUserTypeProperty,
  valueForInitialPageProperty,
  getTemanSevaStatus,
} from 'services/countly/countly'
import { CountlyEventNames } from 'services/countly/eventNames'
import {
  getSessionStorage,
  removeSessionStorage,
  saveSessionStorage,
} from 'utils/handler/sessionStorage'
import { RouteName } from 'utils/navigate'
import { useCar } from 'services/context/carContext'
import { getCustomerInfoSeva } from 'utils/handler/customer'
import { useAnnouncementBoxContext } from 'services/context/announcementBoxContext'
import { useUtils } from 'services/context/utilsContext'
import { useAfterInteractive } from 'utils/hooks/useAfterInteractive'
import dynamic from 'next/dynamic'
import { getCarofTheMonth, getCities, getPromoBanner } from 'services/api'
import { upperSearchNavigationTab } from 'config/searchWidget.config'
import ReportSentry from 'utils/handler/sentry'
import { fetchAndActivate } from 'firebase/remote-config'
import useRemoteConfigValue from 'services/context/remoteConfig'
import { CSAButton } from 'components/atoms'
import { WordpressPromoData } from 'utils/types/utils'

const CitySelectorModal = dynamic(
  () => import('components/molecules').then((mod) => mod.CitySelectorModal),
  { ssr: false },
)

const LeadsFormPrimary = dynamic(
  () => import('components/organisms').then((mod) => mod.LeadsFormPrimary),
  { ssr: false },
)

const PopupLoginNotification = dynamic(
  () =>
    import('components/organisms/popupLoginNotification').then(
      (mod) => mod.PopupLoginNotification,
    ),
  { ssr: false },
)

const HomepageMobile = ({ dataReccomendation, ssr, minMaxPrice }: any) => {
  const { dataCities, dataCarofTheMonth, dataMinMaxYearUsedCar } = useContext(
    HomePageDataLocalContext,
  )
  const { saveRecommendation } = useCar()
  const [openCitySelectorModal, setOpenCitySelectorModal] = useState(false)
  const [cityOtr] = useLocalStorage<CityOtrOption | null>(
    LocalStorageKey.CityOtr,
    null,
  )
  const [dataPromoBanner, setDataPromoBanner] = useState<WordpressPromoData[]>(
    [],
  )
  const [isLoginModalOpened, setIsLoginModalOpened] = useState(false)
  const [carOfTheMonthData, setCarOfTheMonthData] =
    useState<COMData[]>(dataCarofTheMonth)
  const { articles, saveArticles } = useUtils()
  const [articlesTabList, setArticlesTabList] = useState<Article[]>(articles)
  const [isModalOpenend, setIsModalOpened] = useState<boolean>(false)
  const [selectedCarOfTheMonth, setSelectedCarOfTheMonth] =
    useState<COMDataTracking>()

  const enableAnnouncementBoxAleph = useRemoteConfigValue(
    RemoteConfigKey.ToggleEnableAnnouncementBoxAleph,
    true,
  )

  useEffect(() => {
    setArticlesTabList(articles)
  }, [articles])

  const router = useRouter()
  const referalCode = router.query?.ts
  useEffect(() => {
    if (
      referalCode &&
      referalCode.includes('SEVA') &&
      referalCode.length === 8
    ) {
      localStorage.setItem(
        LocalStorageKey.referralTemanSeva,
        referalCode.toString(),
      )
    }
  }, [])

  const getArticle = async () => {
    const response = await fetch(
      'https://www.seva.id/wp-json/foodicious/latest-posts/65',
    )

    const data = await response.json()
    saveArticles(data)
  }

  const getPromoArticle = async () => {
    try {
      const response = await getPromoBanner()
      setDataPromoBanner(response)
    } catch (e) {}
  }

  const [isLeadsFormSectionVisible, setIsLeadsFormSectionVisible] =
    useState(true)
  const [isSentCountlyPageView, setIsSentCountlyPageView] = useState(false)
  const [sourceButton, setSourceButton] = useState('Null')
  const { dataAnnouncementBox } = useUtils()
  const { saveShowAnnouncementBox } = useAnnouncementBoxContext()

  const onClickCategory = async (value: string) => {
    const url = alephArticleCategoryList.find(
      (cat: AlephArticleCategoryType) => cat.value === value,
    )?.url
    if (url) {
      const response = await fetch(url)
      const responseData = await response.json()
      setArticlesTabList(responseData)
    }
  }
  const isIn30DaysInterval = () => {
    const lastTimeSelectCity = getLocalStorage<string>(
      LocalStorageKey.LastTimeSelectCity,
    )
    if (!lastTimeSelectCity) {
      return false
    } else if (
      countDaysDifference(lastTimeSelectCity, new Date().toISOString()) <= 30
    ) {
      return true
    } else {
      return false
    }
  }

  const cityHandler = async () => {
    if (!cityOtr && !isIn30DaysInterval()) {
      setOpenCitySelectorModal(true)
    }
  }

  const cleanEffect = (timeout: NodeJS.Timeout) => {
    clearTimeout(timeout)
  }

  const closeLeadsForm = () => {
    setIsModalOpened(false)
  }

  const scrollToLeadsForm = () => {
    const destinationElm = document.getElementById(
      'landing-page-leads-form-section',
    )
    if (destinationElm) {
      destinationElm.scrollIntoView({
        inline: 'center',
        block: 'center',
      })

      trackEventCountly(CountlyEventNames.WEB_LEADS_FORM_BUTTON_CLICK, {
        PAGE_ORIGINATION: 'Homepage - Floating Icon',
      })
    }
  }

  const trackCountlyPageView = async () => {
    const pageReferrer = getSessionStorage(
      SessionStorageKey.PageReferrerHomepage,
    )
    const previousSourceButton = getSessionStorage(
      SessionStorageKey.PreviousSourceButtonHomepage,
    )
    const referralCodeFromUrl: string | null = getLocalStorage(
      LocalStorageKey.referralTemanSeva,
    )

    const temanSevaStatus = await getTemanSevaStatus(referralCodeFromUrl)

    if (!!window?.Countly?.q) {
      trackEventCountly(CountlyEventNames.WEB_HOMEPAGE_VIEW, {
        PAGE_REFERRER: pageReferrer ?? 'Null',
        PREVIOUS_SOURCE_BUTTON: previousSourceButton ?? 'Null',
        USER_TYPE: valueForUserTypeProperty(),
        INITIAL_PAGE: pageReferrer ? 'No' : valueForInitialPageProperty(),
        TEMAN_SEVA_STATUS: temanSevaStatus,
      })

      setIsSentCountlyPageView(true)
      removeSessionStorage(SessionStorageKey.PageReferrerHomepage)
      removeSessionStorage(SessionStorageKey.PreviousSourceButtonHomepage)
    }
  }

  const getAnnouncementBox = () => {
    if (dataAnnouncementBox) {
      const isShowAnnouncement = getSessionStorage(
        getToken()
          ? SessionStorageKey.ShowWebAnnouncementLogin
          : SessionStorageKey.ShowWebAnnouncementNonLogin,
      )
      if (typeof isShowAnnouncement !== 'undefined') {
        saveShowAnnouncementBox(Boolean(isShowAnnouncement))
      } else {
        saveShowAnnouncementBox(true)
      }
    } else {
      saveShowAnnouncementBox(false)
    }
  }

  useEffect(() => {
    if (
      getCity().cityCode !== 'jakarta' ||
      getCity().cityName === 'Depok' ||
      ssr === 'failed'
    ) {
      // loadCarRecommendation()
      // getCarOfTheMonth()
    }
    getArticle()
    getPromoArticle()
  }, [])

  useAfterInteractive(() => {
    getAnnouncementBox()
  }, [dataAnnouncementBox])

  useAfterInteractive(() => {
    cityHandler()
    setTrackEventMoEngageWithoutValue(EventName.view_homepage)
    setTimeout(() => {
      const timeoutCountlyTracker = setTimeout(() => {
        if (!isSentCountlyPageView) {
          trackCountlyPageView()
        }
      }, 1000)
    })
  }, [])

  const trackLeadsLPForm = (): LeadsActionParam => {
    return {
      Page_Origination: PageOriginationName.LPFloatingIcon,
      ...(cityOtr && { City: cityOtr.cityName }),
    }
  }
  const trackLeadsCotm = (): LeadsActionParam => {
    return {
      Page_Origination: PageOriginationName.COTMLeadsForm,
      ...(cityOtr && { City: cityOtr.cityName }),
      Car_Brand: selectedCarOfTheMonth?.Car_Brand,
      Car_Model: selectedCarOfTheMonth?.Car_Model,
    }
  }

  const onClickMainHeroLP = () => {
    saveSessionStorage(SessionStorageKey.PageReferrerMultiKK, 'Homepage')
    trackEventCountly(
      CountlyEventNames.WEB_HOMEPAGE_CHECK_CREDIT_QUALIFICATION_CLICK,
      {
        SOURCE_SECTION: 'Upper section',
        LOGIN_STATUS: !!getToken() ? 'Yes' : 'No',
        PAGE_ORIGINATION: 'Homepage',
      },
    )
    window.location.href = multiCreditQualificationPageUrl
  }

  const onCtaClickKK = () => {
    window.location.href = multiCreditQualificationPageUrl
  }

  return (
    <>
      <Seo
        title="SEVA - Beli Mobil Baru Dengan Cicilan Kredit Terbaik"
        // description={`Beli mobil baru Toyota, Daihatsu, Isuzu, BMW, Peugeot ${new Date().getFullYear()} secara kredit dengan Instant Approval di SEVA. Proses Aman & Mudah Terintegrasi dengan ACC & TAF. SEVA member of ASTRA`}
        description={`Beli mobil baru Toyota, Daihatsu, Isuzu, BMW ${new Date().getFullYear()} secara kredit dengan Instant Approval di SEVA. Proses Aman & Mudah Terintegrasi dengan ACC & TAF. SEVA member of ASTRA`}
        image={defaultSeoImage}
        additionalTag={
          <link href={getCurrentEnvironment.rootPath} rel="canonical" />
        }
      />

      <main className={styles.main}>
        <WebAnnouncementBox
          onCloseAnnouncementBox={() => null}
          pageOrigination="Homepage"
        />

        <div className={styles.container}>
          <MainHeroLP
            onCityIconClick={() => {
              setOpenCitySelectorModal(true)
              setSourceButton('Location Icon (Navbar)')
            }}
            onCtaClick={onClickMainHeroLP}
            passCountlyTrackerPageView={trackCountlyPageView}
          />
          <SearchWidgetSection
            minMaxPrice={minMaxPrice}
            minMaxYear={dataMinMaxYearUsedCar}
            isShowAnnouncementBox={false}
          />
          <div className={styles.line} />
          <PromoSection onPage={'Homepage'} dataFromApi={dataPromoBanner} />
          <LpCarRecommendations
            dataReccomendation={dataReccomendation}
            onClickOpenCityModal={() => setOpenCitySelectorModal(true)}
            pageOrigination="Homepage"
          />
          <HowToUse onCtaClickKK={onCtaClickKK} pageOrigination="Homepage" />
          {carOfTheMonthData.length > 0 ? (
            <CarOfTheMonth
              carOfTheMonthData={carOfTheMonthData}
              onSendOffer={() => {
                setIsModalOpened(true)
                trackEventCountly(
                  CountlyEventNames.WEB_LEADS_FORM_BUTTON_CLICK,
                  {
                    PAGE_ORIGINATION: 'Homepage - Car of The Month',
                  },
                )
              }}
              cityOtr={cityOtr}
              setSelectedCarOfTheMonth={setSelectedCarOfTheMonth}
            />
          ) : (
            <></>
          )}
          <InView
            as="div"
            id="landing-page-leads-form-section"
            onChange={(inView) =>
              startTransition(() => setIsLeadsFormSectionVisible(inView))
            }
          >
            <LeadsFormTertiary trackGTMLeads={'homepage'} />
          </InView>
          <SubProduct />
          <TestimonyWidget />
          {articles.length > 0 && articlesTabList.length > 0 ? (
            <ArticleWidget
              articles={articles}
              onClickCategory={(value: string) => onClickCategory(value)}
              articlesTabList={articlesTabList}
            />
          ) : (
            <></>
          )}
          <CtaWidget pageOrigination="Homepage" />
          <FooterMobile />
        </div>
        <CitySelectorModal
          isOpen={openCitySelectorModal}
          onClickCloseButton={() => setOpenCitySelectorModal(false)}
          pageOrigination={RouteName.Homepage}
          sourceButton={sourceButton}
        />
        {isModalOpenend && (
          <LeadsFormPrimary
            onCancel={closeLeadsForm}
            onPage={'LP'}
            trackerProperties={trackLeadsCotm()}
            eventGTM={'cotm'}
          />
        )}
        {!isLeadsFormSectionVisible && (
          <CSAButton
            onClick={scrollToLeadsForm}
            additionalstyle={'csa-button-homepage'}
          />
        )}

        {isLoginModalOpened && (
          <PopupLoginNotification
            onCancel={() => setIsLoginModalOpened(false)}
          />
        )}
      </main>
    </>
  )
}

export default HomepageMobile
