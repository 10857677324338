import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconElectric: React.FC<PropsIcon> = ({
  width = 24,
  height = 24,
  alt,
  color = '#246ED4',
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: width,
        height: height,
      }}
    >
      <title>{alt}</title>
      <g clipPath="url(#clip0_23250_10229)">
        <path
          d="M20.3996 5.00008V7.79998C20.3996 8.57304 19.7729 9.20008 18.9995 9.20008V16.2C18.9995 17.3599 18.0594 18.3 16.8995 18.3H14.7995V20.4H3.59961V5.69998C3.59961 4.54005 4.53969 3.59998 5.69961 3.59998H12.6995C13.8594 3.59998 14.7995 4.54005 14.7995 5.69998V16.8999H16.8995C17.286 16.8999 17.5994 16.5865 17.5994 16.2V9.20008C16.8263 9.20008 16.1993 8.57337 16.1993 7.79998V5.00008H17.5994V6.40019H18.9995V5.00008H20.3996ZM13.3997 19.0002V11.3001H11.9996V9.89998H13.3997V5.69998C13.3997 5.31344 13.0864 5.00008 12.6998 5.00008H5.69961C5.31308 5.00008 4.99972 5.31344 4.99972 5.69998V9.89998H6.39983V11.3001H4.99972V19.0002H13.3997Z"
          fill={color}
        />
        <path
          d="M10.1424 10.1743H9.37229L10.2077 8.99109C10.3918 8.73023 10.3294 8.36995 10.0689 8.18587C9.80837 8.00179 9.44776 8.06414 9.26368 8.32467L7.78548 10.4191C7.66112 10.5953 7.64504 10.8266 7.74446 11.0182C7.84388 11.2099 8.04174 11.3303 8.25732 11.3303H9.0796L8.25896 12.5969C8.08538 12.8646 8.16184 13.2226 8.42959 13.3958C8.52671 13.4588 8.63565 13.4887 8.74327 13.4887C8.9326 13.4887 9.11799 13.3958 9.22857 13.2252L10.6274 11.0665C10.7425 10.8886 10.7514 10.6622 10.65 10.4762C10.5486 10.2901 10.354 10.1743 10.1424 10.1743Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_23250_10229">
          <rect
            width="16.8"
            height="16.8"
            fill="white"
            transform="translate(3.59961 3.59998)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
