import { SearchWidget } from 'components/organisms'
import React, { useMemo, useState } from 'react'
import { upperSectionNavigationTab } from 'config/carVariantList.config'
import { NavigationTabUsedCar } from 'components/molecules'
import { CityOtrOption } from 'utils/types/utils'
import styles from 'styles/components/organisms/searchWidgetSection.module.scss'
import { useRouter } from 'next/router'
import { trackEventCountly } from 'services/countly/countly'
import { CountlyEventNames } from 'services/countly/eventNames'
import { capitalizeFirstLetter } from 'utils/handler/string'
// import UsedCarSearchWidget from '../searchWidget/usedCarSearchWidget'
import { upperSearchNavigationTab } from 'config/searchWidget.config'
import { usedCar } from 'services/context/usedCarContext'
import dynamic from 'next/dynamic'
import { CardShadow } from 'components/atoms'

const UsedCarSearchWidget = dynamic(
  () => import('components/organisms').then((mod) => mod.UsedCarSearchWidget),
  { ssr: false },
)

interface Props {
  isShowAnnouncementBox: boolean | null
  isOTO?: boolean
  cityOtr?: CityOtrOption
  minMaxPrice?: any
  minMaxYear?: any
}

export const SearchWidgetSection = ({
  isShowAnnouncementBox,
  isOTO = false,
  cityOtr,
  minMaxPrice,
  minMaxYear,
}: Props) => {
  const router = useRouter()
  const { slug } = router.query
  const [upperTabSlug] = Array.isArray(slug) ? slug : []
  const [selectedTabValue, setSelectedTabValue] = useState(
    upperTabSlug
      ? capitalizeSlugIf360(upperTabSlug)
      : upperSectionNavigationTab[0].value,
  )

  const { carModelDetails } = usedCar()

  const filterTabItem = () => {
    let temp = upperSearchNavigationTab

    return temp
  }

  const tabItemList = useMemo(() => {
    return filterTabItem()
  }, [carModelDetails])

  const onSelectTab = (value: any) => {
    setSelectedTabValue(value)
    trackEventCountly(CountlyEventNames.WEB_HOMEPAGE_SMART_SEARCH_TAB_CLICK, {
      PRODUCT_TYPE: value,
    })
  }

  const renderContent = () => {
    switch (selectedTabValue) {
      case 'Mobil Baru':
        return <SearchWidget minMax={minMaxPrice} />
      case 'Mobil Bekas':
        return <UsedCarSearchWidget minMaxYearLimit={minMaxYear} />

      default:
        return <SearchWidget minMax={minMaxPrice} />
    }
  }

  return (
    <div className={styles.container}>
      <CardShadow className={styles.cardContainer}>
        <div id="pdp-upper-content">
          <NavigationTabUsedCar
            itemList={tabItemList}
            initialTab={upperTabSlug && capitalizeSlugIf360(upperTabSlug)}
            onSelectTab={(value: any) => onSelectTab(value)}
            isShowAnnouncementBox={isShowAnnouncementBox}
            onPage={'search-widget'}
          />

          <>
            <div className={styles.content}>{renderContent()}</div>
          </>
        </div>
      </CardShadow>
    </div>
  )
}

const capitalizeSlugIf360 = (slug: string) => {
  if (slug.toLocaleLowerCase() == '360º eksterior') {
    return slug.slice(0, 4) + ' ' + slug.charAt(5).toUpperCase() + slug.slice(6)
  }
  return capitalizeFirstLetter(slug)
}
