import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconHybrid: React.FC<PropsIcon> = ({
  width,
  height,
  alt,
  color = '#05256E',
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1205 21.6H3.10026C2.49384 21.6 2 21.1094 2 20.503V4.09705C2 3.4924 2.49203 3 3.10026 3H13.1205C13.7269 3 14.2208 3.49059 14.2208 4.09705V20.503C14.2189 21.1085 13.726 21.6 13.1205 21.6ZM3.3617 20.2423H12.8572V4.35866H3.3617V20.2423Z"
        fill={color}
      />
      <path
        d="M11.7313 9.62779H4.48705V5.48192H11.7313V9.62779ZM5.84889 8.27005H10.3697V6.83946H5.84889V8.27005Z"
        fill={color}
      />
      <path
        d="M18.0625 3.6508C18.3895 3.46717 18.8035 3.58285 18.9879 3.90941L20.8765 7.25246L19.69 7.91865L17.8032 4.57869C17.618 4.25092 17.7342 3.8351 18.0625 3.6508Z"
        fill={color}
      />
      <path
        d="M18.1951 19.8485C16.5474 19.8485 15.2067 18.5107 15.2067 16.8688V13.0218C15.2067 12.6344 14.9053 12.3321 14.5213 12.3321L13.5264 12.3312V10.9734H14.5204C15.0724 10.9734 15.5871 11.1862 15.9729 11.5726C16.356 11.9573 16.5702 12.4733 16.5702 13.0209V16.8679C16.5702 17.7622 17.301 18.4899 18.197 18.4899C18.64 18.4899 19.0685 18.3161 19.3699 18.0147C19.6713 17.7142 19.8374 17.3069 19.8374 16.867L19.8383 8.30335H21.2V16.8679C21.2 17.6717 20.8932 18.4175 20.3358 18.9751C19.7766 19.53 18.9958 19.8485 18.1951 19.8485Z"
        fill={color}
      />
      <path
        d="M19.2637 10.2284C18.1997 10.2284 17.3319 9.364 17.3319 8.30227C17.3319 7.24144 18.1988 6.37611 19.2637 6.37611C19.89 6.37611 20.4783 6.67933 20.8414 7.18893C20.9186 7.29574 20.9849 7.41341 21.0366 7.5347C21.1437 7.7809 21.2 8.03977 21.2 8.30227C21.1973 9.3649 20.3303 10.2284 19.2637 10.2284ZM19.2637 7.73467C18.9505 7.73467 18.6936 7.98993 18.6936 8.3031C18.6936 8.61539 18.9496 8.87153 19.2637 8.87153C19.5796 8.87153 19.8356 8.61627 19.8356 8.3031C19.8356 8.22888 19.8192 8.15375 19.7856 8.07681C19.7693 8.04061 19.7511 8.00621 19.7294 7.97725C19.6204 7.82518 19.4479 7.73467 19.2637 7.73467Z"
        fill={color}
      />
      <path
        d="M10.4016 19C9.57432 19 8.90039 18.2545 8.90039 17.3369C8.90039 16.8056 9.12934 16.255 9.56305 15.7467C9.87071 15.3864 10.1794 15.1653 10.1918 15.1567L10.413 15L10.6265 15.171C10.6394 15.1813 10.9403 15.4246 11.2429 15.7923C11.6792 16.3235 11.9004 16.8439 11.9004 17.3369C11.903 18.2535 11.2289 19 10.4016 19ZM10.3903 16.0572C10.0857 16.3376 9.67259 16.8312 9.67259 17.3368C9.67259 17.7814 9.99929 18.145 10.4021 18.145C10.805 18.145 11.1317 17.7831 11.1317 17.3368C11.1312 16.8865 10.6974 16.361 10.3903 16.0572Z"
        fill={color}
        stroke={color}
        stroke-width="0.1"
      />
      <path
        d="M6.62444 12.5488H5.99126L6.67813 11.6737C6.82947 11.4808 6.77821 11.2144 6.56401 11.0782C6.3498 10.9421 6.05331 10.9882 5.90196 11.1809L4.68659 12.7298C4.58434 12.8602 4.57112 13.0312 4.65287 13.173C4.73461 13.3147 4.89729 13.4037 5.07454 13.4037H5.75061L5.07589 14.3404C4.93317 14.5384 4.99603 14.8032 5.21617 14.9313C5.29603 14.9779 5.3856 15 5.47408 15C5.62975 15 5.78218 14.9313 5.87309 14.8051L7.02318 13.2086C7.11787 13.0771 7.12516 12.9097 7.04179 12.7721C6.95843 12.6345 6.79845 12.5488 6.62444 12.5488Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.2992 10.5984C11.5201 10.7641 11.5649 11.0775 11.3992 11.2984L5.39922 19.2984C5.23353 19.5194 4.92013 19.5641 4.69922 19.3984C4.47831 19.2328 4.43353 18.9194 4.59922 18.6984L10.5992 10.6984C10.7649 10.4775 11.0783 10.4328 11.2992 10.5984Z"
        fill={color}
      />
    </svg>
  )
}
