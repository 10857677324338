import { InferGetServerSidePropsType } from 'next'
import { createContext, useEffect } from 'react'
import { HomepageMobile } from 'components/organisms'
import { defaultCity, getCity } from 'utils/hooks/useGetCity'
import { useCar } from 'services/context/carContext'
import { useUtils } from 'services/context/utilsContext'
import {
  Article,
  Banner,
  MobileWebTopMenuType,
  TestimonialData,
} from 'utils/types/utils'
import Script from 'next/script'
import { getToken } from 'utils/handler/auth'
import {
  getRecommendation,
  getBanner,
  getMobileHeaderMenu,
  getCities,
  getTestimony,
  getUsage,
  getMainArticle,
  getTypeCar,
  getCarofTheMonth,
  getAnnouncementBox as gab,
  getMobileFooterMenu,
  getMinMaxYearsUsedCar,
  getModelUsedCar,
  getUsedCarSearch,
  getPromoBanner,
  getMinMaxPrice,
} from 'services/api'
import ReportSentry from 'utils/handler/sentry'
import { logAmplitudeEvent } from 'services/amplitude'
import { TrackingEventName } from 'services/amplitude/types'
import { AmplitudeEventName } from 'services/amplitude/types'
import { getCookies, setCookies } from 'utils/handler/cookies'
import { CookieKey } from 'utils/enum'
import { hasCookie } from 'cookies-next'

interface HomePageDataLocalContextType {
  dataBanner: any
  dataMobileMenu: MobileWebTopMenuType[]
  dataCities: any
  dataTestimony: any
  dataUsage: any
  dataDealerArticle: any
  dataTypeCar: any
  dataCarofTheMonth: any
  dataFooterMenu: any
  dataMinMaxYearUsedCar: any
  dataModelUsedCar: any
}
/**
 * used to pass props without drilling through components
 */
export const HomePageDataLocalContext =
  createContext<HomePageDataLocalContextType>({
    dataBanner: null,
    dataMobileMenu: [],
    dataCities: null,
    dataTestimony: null,
    dataUsage: null,
    dataDealerArticle: null,
    dataTypeCar: null,
    dataCarofTheMonth: null,
    dataFooterMenu: [],
    dataMinMaxYearUsedCar: null,
    dataModelUsedCar: [],
  })

export default function WithTracker({
  dataReccomendation,
  dataBanner,
  dataMobileMenu,
  dataCities,
  dataTestimony,
  dataUsage,
  dataMainArticle,
  dataDealerArticle,
  dataTypeCar,
  dataCarofTheMonth,
  dataMinMaxYearUsedCar,
  dataModelUsedCar,
  ssr,
  dataFooterMenu,
  dataSearchUsedCar,
  dataMinMaxPrice,
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const { saveTypeCar, saveCarOfTheMonth, saveRecommendation } = useCar()
  const {
    saveArticles,
    saveDealerArticles,
    saveMobileWebTopMenus,
    saveDataAnnouncementBox,
    saveMobileWebFooterMenus,
    saveDataSearchUsedCar,
    saveCities,
    saveTestimonial,
  } = useUtils()

  const getAnnouncementBox = async () => {
    try {
      const res: any = await gab({
        headers: {
          'is-login': getToken() ? 'true' : 'false',
        },
      })
      saveDataAnnouncementBox(res.data)
    } catch (error) {
      ReportSentry(`Index Pages - getAnnouncementBox`, error)
    }
  }

  const trackAmplitudeEvent = () => {
    logAmplitudeEvent({
      name: TrackingEventName.WEB_LANDING_PAGE_VIEW,
      data: null,
    })
  }

  useEffect(() => {
    if (
      !dataMobileMenu ||
      !dataMainArticle ||
      !dataBanner ||
      !dataTestimony ||
      !dataCarofTheMonth ||
      !dataFooterMenu ||
      !dataCities ||
      !dataReccomendation
    ) {
      location.reload()
    } else {
      saveMobileWebTopMenus(dataMobileMenu)
      saveDealerArticles(dataDealerArticle)
      saveCarOfTheMonth(dataCarofTheMonth)
      saveTypeCar(dataTypeCar)
      saveMobileWebFooterMenus(dataFooterMenu)
      saveDataSearchUsedCar(dataSearchUsedCar)
      saveCities(dataCities)
      saveRecommendation(dataReccomendation)
      saveTestimonial(dataTestimony)
    }

    getAnnouncementBox()
    trackAmplitudeEvent()
  }, [])

  return (
    <HomePageDataLocalContext.Provider
      value={{
        dataBanner,
        dataMobileMenu,
        dataCities,
        dataTestimony,
        dataUsage,
        dataDealerArticle,
        dataTypeCar,
        dataCarofTheMonth,
        dataFooterMenu,
        dataMinMaxYearUsedCar,
        dataModelUsedCar,
      }}
    >
      <Script
        id="product-jsonld"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(
            jsonLD(dataMainArticle, dataBanner, dataTestimony),
          ),
        }}
        key="product-jsonld"
      />
      <HomepageMobile
        dataReccomendation={dataReccomendation}
        ssr={ssr}
        minMaxPrice={dataMinMaxPrice}
      />
    </HomePageDataLocalContext.Provider>
  )
}

export async function getServerSideProps(context: any) {
  context.res.setHeader(
    'Cache-Control',
    'public, s-maxage=59, stale-while-revalidate=3000',
  )

  const checkCookies = hasCookie(CookieKey.CityOtr, {
    req: context.req,
    res: context.res,
  })

  if (!checkCookies) {
    setCookies(CookieKey.CityOtr, defaultCity, {
      req: context.req,
      res: context.res,
    })
  }

  const getCityOtr = getCookies(CookieKey.CityOtr, {
    req: context.req,
    res: context.res,
  })

  const params = `?city=${getCityOtr.cityCode}&cityId=${getCityOtr.id}`

  const paramsUsedCar = new URLSearchParams()
  paramsUsedCar.append('query', '' as string)
  try {
    const [
      recommendationRes,
      bannerRes,
      menuMobileRes,
      citiesRes,
      testimonyRes,
      usageRes,
      mainArticleRes,
      typeCarRes,
      carofTheMonthRes,
      footerMenuRes,
      minmaxYearRes,
      modelUsedCarRes,
      dataSearchRes,
      minMaxPriceRes,
    ]: any = await Promise.allSettled([
      getRecommendation(params + '&sortBy=lowToHigh&limit=5&page=1'),
      getBanner(),
      getMobileHeaderMenu(),
      getCities(),
      getTestimony(''),
      getUsage(),
      getMainArticle('65'),
      getTypeCar('?city=jakarta'),
      getCarofTheMonth('?city=jakarta'),
      getMobileFooterMenu(),
      getMinMaxYearsUsedCar(''),
      getModelUsedCar(''),
      getUsedCarSearch('', { params: paramsUsedCar }),
      getMinMaxPrice(params),
    ])

    const [
      dataReccomendation,
      dataBanner,
      dataMobileMenu,
      dataCities,
      dataTestimony,
      dataUsage,
      dataMainArticle,
      dataDealerArticle,
      dataTypeCar,
      dataCarofTheMonth,
      dataFooterMenu,
      dataMinMaxYearUsedCar,
      dataModelUsedCar,
      dataSearchUsedCar,
      dataMinMaxPrice,
    ] = await Promise.all([
      recommendationRes.status === 'fulfilled'
        ? recommendationRes.value.carRecommendations
        : [],
      bannerRes.status === 'fulfilled' ? bannerRes.value.data : [],
      menuMobileRes.status === 'fulfilled' ? menuMobileRes.value.data : [],
      citiesRes.status === 'fulfilled' ? citiesRes.value : [],
      testimonyRes.status === 'fulfilled' ? testimonyRes.value.data : [],
      usageRes.status === 'fulfilled' ? usageRes.value.data.attributes : [],
      mainArticleRes.status === 'fulfilled' ? mainArticleRes.value : [],
      mainArticleRes.status === 'fulfilled'
        ? mainArticleRes.value.filter(
            (article: Article) => article.category === 'Review Otomotif',
          )
        : [],
      typeCarRes.status === 'fulfilled' ? typeCarRes.value : [],
      carofTheMonthRes.status === 'fulfilled'
        ? carofTheMonthRes.value.data
        : [],
      footerMenuRes.status === 'fulfilled' ? footerMenuRes.value.data : [],
      minmaxYearRes.status === 'fulfilled' ? minmaxYearRes.value.data : [],
      modelUsedCarRes.status === 'fulfilled' ? modelUsedCarRes.value.data : [],
      dataSearchRes.status === 'fulfilled' ? dataSearchRes.value.data : [],
      minMaxPriceRes.status === 'fulfilled'
        ? minMaxPriceRes.value
        : { minPriceValue: 0, maxPriceValue: 0 },
    ])

    return {
      props: {
        dataReccomendation,
        dataBanner,
        dataMobileMenu,
        dataCities,
        dataTestimony,
        dataUsage,
        dataMainArticle,
        dataDealerArticle,
        dataTypeCar,
        dataCarofTheMonth,
        dataMinMaxYearUsedCar,
        dataModelUsedCar,
        ssr: 'success',
        dataFooterMenu,
        dataSearchUsedCar,
        dataMinMaxPrice,
      },
    }
  } catch (error: any) {
    ReportSentry(`Index Pages - getServerSideProps`, error)
    return {
      props: {
        dataReccomendation: [],
        dataBanner: [],
        dataMobileMenu: [],
        dataCities: [],
        dataTestimony: [],
        dataUsage: [],
        dataMainArticle: [],
        dataDealerArticle: [],
        dataTypeCar: [],
        dataCarofTheMonth: [],
        dataMinMaxYearUsedCar: [],
        dataModelUsedCar: [],
        dataDesktopMenu: [],
        ssr: 'failed',
        dataFooterMenu: [],
        dataSearchUsedCar: [],
        dataPromoBanner: [],
        dataMinMaxPrice: { minPriceValue: 0, maxPriceValue: 0 },
      },
    }
  }
}

const jsonLD = (
  dataMainArticles?: Article[],
  dataBanners?: Banner[],
  dataReviews?: TestimonialData[],
) => {
  return {
    Organization: {
      '@type': 'Organization',
      name: 'SEVA by Astra',
      url: 'https://www.seva.id/',
      logo: 'https://www.seva.id/static/media/seva-header.30f3b7238c6c0f5cea869e76e5924de4.svg',
      image: 'logo-primary.webp',
      sameAs: [
        'https://www.instagram.com/sevabyastra/',
        'https://twitter.com/sevaid_official',
        'https://www.facebook.com/sevabyastra/',
      ],
      address: {
        '@type': 'PostalAddress',
        addressLocality: 'Jakarta',
        addressRegion: 'Jakarta',
        postalCode: '10220',
        streetAddress:
          'Jl. Jenderal Sudirman No.Kav.5-6, RT.10/RW.6, Karet Tengsin, Kecamatan Tanah Abang',
        telephone: '(021) 50821999',
      },
      potentialAction: {
        '@type': 'SearchAction',
        target: {
          '@type': 'EntryPoint',
          urlTemplate:
            'https://www.seva.id/search/result?q={search_term_string}',
          'query-input': {
            '@type': 'PropertyValueSpecification',
            valueRequired: 'https://schema.org/True',
            valueName: 'search_term_string',
          },
        },
      },
    },
    ImageObject: dataBanners?.map((banner) => ({
      '@type': 'ImageObject',
      contentUrl:
        banner.attribute.web_mobile?.split('/')?.[
          banner.attribute.web_mobile.split('/')?.length - 1
        ],
      mainEntityOfPage: banner.attribute.web_mobile,
      representativeOfPage: 'https://schema.org/True',
      isFamilyFriendly: 'https://schema.org/True',
      isAccesibleForFree: 'https://schema.org/False',
    })),
    Review: dataReviews?.map((review) => ({
      '@type': 'Review',
      name: 'Cerita Pengguna SEVA',
      itemReviewed: {
        '@type': 'Car',
        image: review.pictureUrl,
        name: review.name,
        reviewRating: {
          '@type': 'Rating',
          ratingValue: review.rating,
          publisher: {
            '@type': 'Organization',
            name: 'SEVA by Astra',
          },
        },
      },
    })),
    SiteNavigationElement: {
      '@type': 'SiteNavigationElement',
      name: 'SEVA',
      potentialAction: [
        {
          '@type': 'Action',
          name: 'Mobil',
          url: 'https://www.seva.id/mobil-baru',
        },
        {
          '@type': 'Action',
          name: 'Fasilitas Dana',
          url: 'https://www.seva.id/fasilitas-dana',
        },
        {
          '@type': 'Action',
          name: 'Layanan Surat Kendaraan',
          url: 'https://www.seva.id/layanan-surat-kendaraan',
        },
        {
          '@type': 'Action',
          name: 'Tentang SEVA',
          url: 'https://www.seva.id/info/tentang-kami/',
        },
        {
          '@type': 'Action',
          name: 'Promo',
          url: 'https://www.seva.id/info/promo/',
        },
        {
          '@type': 'Action',
          name: 'Teman SEVA',
          url: 'https://www.seva.id/teman-seva/onboarding',
        },
        {
          '@type': 'Action',
          name: 'Berita Utama Otomotif',
          url: 'https://www.seva.id/blog/category/otomotif/',
        },
        {
          '@type': 'Action',
          name: 'Review Otomotif',
          url: 'https://www.seva.id/blog/category/otomotif/review-otomotif/',
        },
        {
          '@type': 'Action',
          name: 'Tips & Rekomendasi',
          url: 'https://www.seva.id/blog/category/otomotif/tips-rekomendasi-otomotif/',
        },
        {
          '@type': 'Action',
          name: 'Keuangan',
          url: 'https://www.seva.id/blog/category/keuangan/',
        },
        {
          '@type': 'Action',
          name: 'Semua Artikel',
          url: 'https://www.seva.id/blog/',
        },
        {
          '@type': 'Action',
          name: 'Akun Saya',
          url: 'https://www.seva.id/akun/profil',
        },
      ],
    },
    NewsArticle: dataMainArticles?.map((article) => ({
      '@type': 'NewsArticle',
      mainEntityOfPage: 'https://www.seva.id/',
      headline: article.title,
      abstract: article.excerpt,
      image: article.featured_image,
      datePublished: new Date(article.publish_date).toLocaleDateString(
        'id-ID',
        { dateStyle: 'long' },
      ),
      dateModified: new Date(article.publish_date).toLocaleDateString('id-ID', {
        dateStyle: 'long',
      }),
      author: {
        '@type': 'Person',
        name: article.writer_name,
      },
      publisher: {
        '@type': 'Organization',
        name: 'SEVA by Astra',
        logo: {
          '@type': 'ImageObject',
          url: 'https://cdn.seva.id/blog/media/2022/07/Seva-LogoxAF_Seva-PrimarybyAstraFinancial3.png',
        },
      },
    })),
    Website: {
      '@type': 'Website',
      name: 'SEVA by Astra',
      url: 'https://www.seva.id/',
    },
  }
}
