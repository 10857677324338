import React from 'react'
import styles from 'styles/components/organisms/articleWidget.module.scss'
import { Article } from 'utils/types'
import Image from 'next/image'
import { sendAmplitudeData } from 'services/amplitude'
import { AmplitudeEventName } from 'services/amplitude/types'
import { articleDateFormat } from 'utils/handler/date'
import { LanguageCode, SessionStorageKey } from 'utils/enum'
import { trackEventCountly } from 'services/countly/countly'
import { CountlyEventNames } from 'services/countly/eventNames'
import { saveSessionStorage } from 'utils/handler/sessionStorage'
import { defineRouteName } from 'utils/navigate'
import Link from 'next/link'

type ArticlesWidgetProps = {
  article: Article
  currentTab: string
  articleOrder: number
}
const ArticleWidgetCard = ({
  article,
  currentTab,
  articleOrder,
}: ArticlesWidgetProps) => {
  return (
    <div>
      <Link
        href={article.url}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          trackEventCountly(CountlyEventNames.WEB_ARTICLE_CLICK, {
            PAGE_ORIGINATION: 'Homepage',
            ARTICLE_SECTION: 'Main article',
            ARTICLE_ORDER: articleOrder + 1,
            ARTICLE_CATEGORY: article.category.replace('&', 'dan'),
            PAGE_DIRECTION_URL: article.url,
          })
          saveSessionStorage(
            SessionStorageKey.PageReferrerBlog,
            defineRouteName(window.location.pathname + window.location.search),
          )
          if (e.button === 0 && e.ctrlKey) {
            window.open(article.url, '_blank')
          } else {
            window.location.href = article.url
          }
        }}
        onMouseDown={(e) => {
          e.preventDefault()
          e.stopPropagation()
          if (e.button === 1 || e.button === 2) {
            trackEventCountly(CountlyEventNames.WEB_ARTICLE_CLICK, {
              PAGE_ORIGINATION: 'Homepage',
              ARTICLE_SECTION: 'Main article',
              ARTICLE_ORDER: articleOrder + 1,
              ARTICLE_CATEGORY: article.category.replace('&', 'dan'),
              PAGE_DIRECTION_URL: article.url,
            })
            saveSessionStorage(
              SessionStorageKey.PageReferrerBlog,
              defineRouteName(
                window.location.pathname + window.location.search,
              ),
            )
          }
        }}
      >
        <div className={styles.cardArticle}>
          <Image
            alt="article"
            src={article.featured_image}
            width={480}
            height={258}
            className={styles.images}
          />
          {article.category && (
            <div className={styles.categoryTextWrapper}>
              <p>{article.category}</p>
            </div>
          )}
          <div className={styles.descriptionWrapper}>
            <p className={styles.textDate}>
              {articleDateFormat(
                new Date(article.publish_date),
                LanguageCode.id,
              )}
            </p>
            <h4 className={styles.textTitle}>{article.title}</h4>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default ArticleWidgetCard
