import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconBattery: React.FC<PropsIcon> = ({
  width = 24,
  height = 24,
  alt,
  color = '#246ED4',
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: width,
        height: height,
      }}
    >
      <title>{alt}</title>
      <g clipPath="url(#clip0_23250_10221)">
        <path
          d="M12.8219 11.9274H11.9932L12.8922 10.6542C13.0902 10.3735 13.0232 9.98581 12.7428 9.78773C12.4625 9.58965 12.0744 9.65673 11.8763 9.93708L10.2857 12.1908C10.1519 12.3804 10.1346 12.6293 10.2416 12.8355C10.3486 13.0417 10.5615 13.1713 10.7934 13.1713H11.6783L10.7952 14.5342C10.6084 14.8223 10.6907 15.2075 10.9788 15.394C11.0833 15.4618 11.2005 15.4939 11.3164 15.4939C11.5201 15.4939 11.7196 15.394 11.8386 15.2104L13.3438 12.8874C13.4677 12.6961 13.4772 12.4524 13.3681 12.2522C13.259 12.052 13.0496 11.9274 12.8219 11.9274Z"
          fill={color}
        />
        <path
          d="M17.3868 4.76762H17.3052V3.99402C17.3052 3.77652 17.1287 3.59998 16.9112 3.59998H13.6441C13.4266 3.59998 13.25 3.77652 13.25 3.99402V4.76762H13.1685C12.654 4.76762 12.1834 4.95793 11.8222 5.27111C11.461 4.95793 10.9903 4.76762 10.4759 4.76762H10.3943V3.99402C10.3943 3.77652 10.2178 3.59998 10.0003 3.59998H6.73321C6.51571 3.59998 6.33917 3.77652 6.33917 3.99402V4.76762H6.2576C5.12139 4.76762 4.2002 5.68881 4.2002 6.82538V18.3426C4.2002 19.4791 5.12139 20.4003 6.25796 20.4003H10.4762C10.9907 20.4003 11.4613 20.21 11.8225 19.8968C12.1837 20.21 12.6544 20.4003 13.1688 20.4003H17.3871C18.5237 20.4003 19.4449 19.4791 19.4449 18.3426V6.82538C19.4449 5.68881 18.5237 4.76762 17.3871 4.76762H17.3868ZM18.0322 18.3422C18.0322 18.6985 17.7434 18.9876 17.3868 18.9876H13.1685C12.8458 18.9876 12.5785 18.7493 12.5312 18.4393C12.5319 18.4259 12.5322 18.2924 12.5326 18.1378C12.5333 17.861 12.3091 17.636 12.0323 17.636H11.6121C11.3353 17.636 11.1111 17.861 11.1118 18.1378C11.1121 18.2924 11.1128 18.4259 11.1132 18.4393C11.0662 18.749 10.7986 18.9876 10.4759 18.9876H6.25796C5.9017 18.9876 5.61252 18.6988 5.61252 18.3422V6.82538C5.61252 6.46912 5.90134 6.17995 6.25796 6.17995H10.4762C10.7989 6.17995 11.0662 6.41828 11.1135 6.72828C11.1128 6.7417 11.1125 6.87516 11.1121 7.02981C11.1114 7.30663 11.3356 7.53154 11.6124 7.53154H12.0326C12.3094 7.53154 12.5336 7.30663 12.5329 7.02981C12.5326 6.87516 12.5319 6.7417 12.5315 6.72828C12.5785 6.41863 12.8461 6.17995 13.1688 6.17995H17.3871C17.7434 6.17995 18.0325 6.46877 18.0325 6.82538V18.3426L18.0322 18.3422Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_23250_10221">
          <rect
            width="15.2443"
            height="16.8"
            fill="white"
            transform="translate(4.2002 3.59998)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
