import {
  trackPromoBannerSeeAllClick,
  trackSeeAllPromoClick,
} from 'services/amplitude/seva20Tracking'
import React, { useMemo } from 'react'
import styles from 'styles/components/organisms/summary.module.scss'

const promoBannerCumaDiSEVA = '/revamp/illustration/PromoCumaDiSEVA.webp'
const promoBannerCashback = '/revamp/illustration/PromoCashback.webp'

import {
  IconChevronRight,
  IconEngine,
  IconFuel,
  IconPromo,
  IconSeat,
  IconTransmission,
  IconCar,
  IconBattery,
  IconElectric,
} from 'components/atoms'
import {
  CarVariantRecommendation,
  PromoDetail,
  WordpressPromoData,
  trackDataCarType,
} from 'utils/types/utils'
import { OTOVariantListUrl, variantListUrl } from 'utils/helpers/routes'
import elementId from 'utils/helpers/elementId/elementIds'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { trackEventCountly } from 'services/countly/countly'
import { CountlyEventNames } from 'services/countly/eventNames'
import { getLocalStorage } from 'utils/handler/localStorage'
import { LocalStorageKey, RemoteConfigKey, SessionStorageKey } from 'utils/enum'
import { LoanRank } from 'utils/types/models'
import { getBrandAndModelValue } from 'utils/handler/getBrandAndModel'
import {
  getSessionStorage,
  saveSessionStorage,
} from 'utils/handler/sessionStorage'
import { defineRouteName } from 'utils/navigate'
import { IconHybrid } from 'components/atoms/icon/Hybrid'
import { Wording } from 'utils/helpers/glosary/glosary'
import Link from 'next/link'
import { Swiper, SwiperSlide } from 'components/atoms/swiper'
import useRemoteConfigValue from 'services/context/remoteConfig'

type PromoSectionProps = {
  setPromoName?: (value: string) => void
  dataForAmplitude?: any
  onButtonClick?: (value: boolean) => void
  cheapestVariantData?: CarVariantRecommendation | undefined
  info?: any
  onPage?: 'dealer' | 'VariantListPage' | 'Homepage'
  setSelectedTabValue?: (value: string) => void
  isOTO?: boolean
  dataFromApi?: WordpressPromoData[]
  dataFromCms?: PromoDetail[]
}

const PromoSection = ({
  setPromoName,
  dataForAmplitude,
  onButtonClick,
  cheapestVariantData,
  info,
  onPage,
  setSelectedTabValue,
  isOTO = false,
  dataFromApi,
  dataFromCms,
}: PromoSectionProps) => {
  const router = useRouter()
  const brand = router.query.brand as string
  const model = router.query.model as string
  const enablePromoCumaDiSeva = isOTO

  const enablePromoWp = useRemoteConfigValue(
    RemoteConfigKey.ToggleEnablePromoWP,
    true,
  )

  const IsShowBadgeCreditOpportunity = getSessionStorage(
    SessionStorageKey.IsShowBadgeCreditOpportunity,
  )
  const filterStorage: any = getLocalStorage(LocalStorageKey.CarFilter)
  const dataCar: trackDataCarType | null = getSessionStorage(
    SessionStorageKey.PreviousCarDataBeforeLogin,
  )
  const isUsingFilterFinancial =
    !!filterStorage?.age &&
    !!filterStorage?.downPaymentAmount &&
    !!filterStorage?.monthlyIncome &&
    !!filterStorage?.tenure

  const gasCapacityWording =
    'Mesin ' + cheapestVariantData?.engineCapacity + ' cc'
  const electricCapacityWording = `Baterai ${cheapestVariantData?.batteryCapacity} kWh`

  const engineCapacity =
    cheapestVariantData?.fuelType === 'Electric'
      ? electricCapacityWording
      : gasCapacityWording

  const navigateToSpecificationTab = () => {
    setSelectedTabValue && setSelectedTabValue('Spesifikasi')
    window.location.href = (isOTO ? OTOVariantListUrl : variantListUrl)
      .replace(':brand', brand)
      .replace(':model', model)
      .replace(':tab?', 'spesifikasi')
  }
  const trackCountlyClickSeeAll = () => {
    trackEventCountly(CountlyEventNames.WEB_HOMEPAGE_PROMO_BANNER_ALL_CLICK)
  }
  const trackCountlyClicPromo = (promoUrl: string, promoOrder: number) => {
    trackEventCountly(CountlyEventNames.WEB_HOMEPAGE_PROMO_BANNER_CLICK, {
      PAGE_DIRECTION_URL: promoUrl,
      PROMO_ORDER: promoOrder,
    })
  }

  const trackCountlePromoCLick = (promoDetail: string, promoOrder: number) => {
    trackEventCountly(CountlyEventNames.WEB_PROMO_CLICK, {
      CAR_BRAND: getBrandAndModelValue(brand),
      CAR_MODEL: getBrandAndModelValue(model),
      PROMO_DETAILS: promoDetail,
      PROMO_ORDER: promoOrder,
      PELUANG_KREDIT_BADGE:
        isUsingFilterFinancial && IsShowBadgeCreditOpportunity
          ? dataCar?.PELUANG_KREDIT_BADGE
          : 'Null',
      PAGE_ORIGINATION: 'PDP',
    })
  }

  const publishedPromo = useMemo(() => {
    if (dataFromApi && dataFromApi.length > 0) {
      return dataFromApi.filter(
        (item) =>
          item.publish_status === 'publish' &&
          item.show_homepage === true &&
          !!item.image_url &&
          !!item.permalink,
      )
    } else {
      return []
    }
  }, [dataFromApi])

  const publishedPromoCMS = useMemo(() => {
    if (dataFromCms && dataFromCms.length > 0) {
      return dataFromCms.filter(
        (item) =>
          item.promo_banner_detail_page.length > 1 &&
          item.promo_banner_thumbnail.length > 1,
      )
    } else {
      return []
    }
  }, [dataFromCms])

  const renderSeeAllPromoButton = () => {
    if (onPage === 'dealer') {
      return <></>
    } else if (onPage === 'VariantListPage' && !!info?.isPassengerCar) {
      return <></>
    } else if (onPage === 'VariantListPage' && !info?.isPassengerCar) {
      return (
        <a
          href="https://www.seva.id/info/promo/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.openSans}
          style={{ color: '#246ED4', paddingRight: '16px' }}
          onClick={() => trackSeeAllPromoClick(dataForAmplitude)}
          data-testid={elementId.PDP.CTA.LihatSemuaPromo}
        >
          Lihat Semua
        </a>
      )
    } else {
      return (
        <a
          href="https://www.seva.id/info/promo/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.openSansMedium}
          style={{ color: '#246ED4', paddingRight: '16px' }}
          onClick={() => {
            trackPromoBannerSeeAllClick()
            trackCountlyClickSeeAll()
            saveSessionStorage(
              SessionStorageKey.PageReferrerPromo,
              defineRouteName(
                window.location.pathname + window.location.search,
              ),
            )
          }}
          data-testid={elementId.Homepage.Promo.LihatSemua}
        >
          Lihat semua
        </a>
      )
    }
  }

  const renderListForHomepage = () =>
    publishedPromo.map((item, index) => {
      return (
        <SwiperSlide
          className={styles.bannerPromo}
          key={index}
          style={{
            width: onPage === 'dealer' ? 260.97 : 208,
            height: onPage === 'dealer' ? 196 : 156,
          }}
        >
          <Link
            href={item.permalink}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              trackCountlyClicPromo(item.permalink, index + 1)
              saveSessionStorage(
                SessionStorageKey.PageReferrerPromo,
                defineRouteName(
                  window.location.pathname + window.location.search,
                ),
              )
              window.open(item.permalink, '_blank')
            }}
            onMouseDown={(e) => {
              e.preventDefault()
              e.stopPropagation()

              if (e.button === 1 || e.button === 2) {
                trackCountlyClicPromo(item.permalink, index + 1)
                saveSessionStorage(
                  SessionStorageKey.PageReferrerPromo,
                  defineRouteName(
                    window.location.pathname + window.location.search,
                  ),
                )
              }
            }}
            data-testid={elementId.Homepage.Promo.DetailPromo}
          >
            <Image
              src={item.image_url}
              className={styles.promoBannerSmall}
              height={onPage === 'dealer' ? 196 : 156}
              width={onPage === 'dealer' ? 260.97 : 208}
              alt={item.title ?? 'Promo Illustration'}
              priority
            />

            <div
              style={{
                backgroundImage: `linear-gradient(to bottom, rgba(255,0,0,0), black)`,
              }}
            >
              <p className={styles.textPromoBanner}>
                Lihat detail
                <span className={styles.spacingChevronIcon}>
                  <IconChevronRight width={16} height={16} color="#FFFFFF" />
                </span>
              </p>
            </div>
          </Link>
        </SwiperSlide>
      )
    })

  const renderPromoCumaDiSeva = () => {
    return (
      <div
        className={styles.bannerPromo}
        onClick={() => {
          if (onPage === 'VariantListPage') {
            onButtonClick && onButtonClick(true)
            setPromoName && setPromoName('promo1')
            trackCountlePromoCLick('Promo Cuma di SEVA', 1)
          } else {
            const Page_Direction_URL =
              'https://www.seva.id/info/promo/cuma-di-seva/'
            window.open(Page_Direction_URL, '_blank')
          }
        }}
        data-testid={
          onPage === 'VariantListPage'
            ? elementId.PDP.CTA.LihatDetail
            : elementId.Homepage.Promo.DetailPromo
        }
      >
        <Image
          src={promoBannerCumaDiSEVA}
          className={styles.promoBannerSmall}
          height={156}
          width={208}
          alt="Promo Cuma di SEVA dapat Asuransi Comprehensive dan Cashback"
          loading="lazy"
        />
        <div>
          <p className={styles.textPromoBanner}>
            Lihat detail{' '}
            <span className={styles.spacingChevronIcon}>
              <IconChevronRight width={16} height={16} color="#FFFFFF" />
            </span>
          </p>
        </div>
      </div>
    )
  }

  const renderPromoForPassengerCar = () => {
    return (
      <div
        className={styles.bannerPromo}
        onClick={() => {
          onButtonClick && onButtonClick(true)
          setPromoName && setPromoName('promo4')
          trackCountlePromoCLick(
            'Asuransi & Cashback SEVA',
            enablePromoCumaDiSeva ? 2 : 1,
          )
        }}
        data-testid={elementId.Homepage.Promo.DetailPromo}
      >
        <Image
          src={promoBannerCashback}
          className={styles.promoBannerSmall}
          height={156}
          width={208}
          alt="Asuransi & Cashback SEVA"
          loading="lazy"
        />

        <div
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(255,0,0,0), #13131B)`,
          }}
        >
          <p className={styles.textPromoBanner}>
            Lihat detail
            <span className={styles.spacingChevronIcon}>
              <IconChevronRight width={16} height={16} color="#FFFFFF" />
            </span>
          </p>
        </div>
      </div>
    )
  }

  const renderListWithCMSData = () => {
    return (
      <>
        {publishedPromoCMS.map((promo, index) => (
          <SwiperSlide
            key={index}
            className={styles.bannerPromo}
            onClick={() => {
              onButtonClick && onButtonClick(true)
              setPromoName && setPromoName(`promoCMS-${promo.promo_name}`)
              trackCountlePromoCLick(promo.promo_name, index + 1)
            }}
            data-testid={elementId.Homepage.Promo.DetailPromo}
            style={{
              width: 208,
              height: 156,
            }}
          >
            <Image
              src={promo.promo_banner_detail_page[0].media_url || ''}
              className={styles.promoBannerSmall}
              height={156}
              width={208}
              alt={promo.promo_name}
              loading="lazy"
            />

            <div
              style={{
                backgroundImage: `linear-gradient(to bottom, rgba(255,0,0,0), #13131B)`,
              }}
            >
              <p className={styles.textPromoBanner}>
                Lihat detail
                <span className={styles.spacingChevronIcon}>
                  <IconChevronRight width={16} height={16} color="#FFFFFF" />
                </span>
              </p>
            </div>
          </SwiperSlide>
        ))}
      </>
    )
  }

  if (
    !enablePromoWp ||
    ((onPage === 'Homepage' || onPage === 'dealer') &&
      dataFromApi &&
      publishedPromo.length === 0) ||
    (onPage === 'VariantListPage' && publishedPromoCMS.length === 0)
  )
    return <></>

  return (
    <div>
      {onPage === 'VariantListPage' && (
        <div className={styles.cardInfoDetail} style={{ padding: '16px' }}>
          <div className={styles.row}>
            <div className={styles.rowWithGap}>
              <IconCar
                width={24}
                height={24}
                color={'#B4231E'}
                alt="SEVA Car Icon"
              />
              <h3 className={styles.kanyonMedium}>Detail</h3>
            </div>
            <div
              className={styles.rowWithGap}
              onClick={navigateToSpecificationTab}
              style={{ justifyContent: 'end' }}
              data-testid={elementId.PDP.CTA.LihatDetail}
            >
              <p className={styles.openSansLink} style={{ color: '#246ED4' }}>
                {'Lihat Detail'}
              </p>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.rowWithGap}>
              <IconSeat
                width={24}
                height={24}
                color={'#246ED4'}
                alt="SEVA Chair Icon"
              />
              <p className={styles.openSans} style={{ color: '#13131B' }}>
                {info.seats + ' ' + Wording.Seater}
              </p>
            </div>
            <div className={styles.rowWithGap}>
              <IconTransmission
                width={24}
                height={24}
                color={'#246ED4'}
                alt="SEVA Transmition gear Icon"
              />
              <p className={styles.openSans} style={{ color: '#13131B' }}>
                {cheapestVariantData?.transmission}
              </p>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.rowWithGap}>
              {cheapestVariantData?.fuelType === 'Electric' ? (
                <IconBattery
                  width={24}
                  height={24}
                  color={'#246ED4'}
                  alt="SEVA Engine Icon"
                />
              ) : (
                <IconEngine
                  width={24}
                  height={24}
                  color={'#246ED4'}
                  alt="SEVA Engine Icon"
                />
              )}
              <p className={styles.openSans} style={{ color: '#13131B' }}>
                {engineCapacity}
              </p>
            </div>
            <div className={styles.rowWithGap}>
              {cheapestVariantData?.fuelType === 'Electric' ? (
                <IconElectric
                  width={24}
                  height={24}
                  color={'#246ED4'}
                  alt="SEVA Gas Station icon"
                />
              ) : cheapestVariantData?.fuelType === 'Hybrid' ? (
                <IconHybrid
                  width={24}
                  height={24}
                  color={'#246ED4'}
                  alt="SEVA Engine Icon"
                />
              ) : (
                <IconFuel
                  width={24}
                  height={24}
                  color={'#246ED4'}
                  alt="SEVA Gas Station icon"
                />
              )}

              <p className={styles.openSans} style={{ color: '#13131B' }}>
                {cheapestVariantData?.fuelType === 'Electric'
                  ? 'Listrik'
                  : cheapestVariantData?.fuelType}
              </p>
            </div>
          </div>
        </div>
      )}
      {onPage === 'VariantListPage' && !info?.isPassengerCar ? (
        <></>
      ) : (
        <div
          className={
            onPage === 'dealer'
              ? styles.cardPromoDetailDealer
              : styles.cardPromoDetail
          }
        >
          <div
            className={styles.row}
            style={{ justifyContent: 'space-between', paddingLeft: '16px' }}
          >
            <div className={styles.rowWithGap}>
              {onPage === 'VariantListPage' ? (
                <div className={styles.headerWrapper}>
                  <IconPromo
                    width={24}
                    height={24}
                    color={'#B4231E'}
                    alt="SEVA Price Tag icon"
                  />
                  <h3 className={styles.kanyonMedium}>Promo</h3>
                </div>
              ) : (
                <h2
                  className={styles.kanyonMediumBlue}
                  data-testid={elementId.Homepage.Button.PromoEkslusif}
                >
                  Promo Eksklusif
                </h2>
              )}
            </div>
            {renderSeeAllPromoButton()}
          </div>
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={15}
            className={styles.rowScrollHorizontal}
          >
            {onPage === 'Homepage' || onPage === 'dealer'
              ? renderListForHomepage()
              : renderListWithCMSData()}
          </Swiper>
        </div>
      )}
    </div>
  )
}

export default PromoSection
