export const upperSearchNavigationTab = [
  {
    label: 'Mobil Baru',
    value: 'Mobil Baru',
  },
  {
    label: 'Mobil Bekas',
    value: 'Mobil Bekas',
  },
]
