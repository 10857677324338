import React, { MutableRefObject, useRef, useState } from 'react'
import styles from 'styles/components/organisms/testimonyWidget.module.scss'
import 'swiper/css'
import 'swiper/css/pagination'
import { differentDateStatus } from 'utils/handler/date'
import { sendAmplitudeData } from 'services/amplitude'
import { AmplitudeEventName } from 'services/amplitude/types'
import Image from 'next/image'
import { TestimonialData } from 'utils/types/props'
import { CardShadow } from 'components/atoms'
import { trackEventCountly } from 'services/countly/countly'
import { CountlyEventNames } from 'services/countly/eventNames'
import Link from 'next/link'
import DOMPurify from 'dompurify'
import dynamic from 'next/dynamic'
import { useUtils } from 'services/context/utilsContext'
import { Swiper, SwiperSlide } from 'components/atoms/swiper'

const PopupTestimony = dynamic(
  () => import('components/organisms/popupTestimony'),
  { ssr: false },
)

const TestimonyWidget = () => {
  const { testimonials } = useUtils()
  const [openTestimony, setOpenTestimony] = useState<TestimonialData | null>(
    null,
  )
  const [indexCard, setIndexCard] = useState(1)
  const [hasTrackedSlide, setHasTrackedSlide] = useState(false)
  const cardRef = useRef() as MutableRefObject<HTMLDivElement>
  const descRef = useRef() as MutableRefObject<HTMLDivElement>

  if (testimonials.length === 0) return <></>

  return (
    <>
      <div className={styles.container}>
        <div className={styles.row}>
          <h2 className={styles.title}>Cerita Pengguna SEVA</h2>
          <Link
            href="/cerita-pengguna"
            rel="noopener noreferrer"
            className={styles.textLink}
            onClick={() => {}}
          >
            Lihat semua
          </Link>
        </div>
        <Swiper
          spaceBetween={20}
          pagination
          className={styles.wrapper}
          onSlideChange={({ activeIndex }: { activeIndex: number }) => {
            if (!hasTrackedSlide) {
              setHasTrackedSlide(true)
              trackEventCountly(CountlyEventNames.WEB_HOMEPAGE_TESTIMONY_SLIDE)
            }
            setIndexCard(activeIndex + 1)
            sendAmplitudeData(
              AmplitudeEventName.WEB_LP_TESTIMONY_NEXT_PREV_CLICK,
              {
                Numbers_of_Card_Viewed: String(activeIndex + 1),
              },
            )
          }}
        >
          {testimonials
            .sort((a, b) => a.displayNumber - b.displayNumber)
            .map((item, index) => (
              <SwiperSlide key={index} className={styles.content}>
                <Image
                  src={item.pictureUrl}
                  alt={item.pictureName}
                  width={199}
                  height={149}
                  loading="lazy"
                />
                <CardShadow
                  ref={cardRef}
                  className={styles.card}
                  role="button"
                  onClick={() => {
                    setOpenTestimony(item)
                    sendAmplitudeData(
                      AmplitudeEventName.WEB_LP_TESTIMONY_CLICK,
                      {
                        Order: String(indexCard),
                      },
                    )
                    trackEventCountly(
                      CountlyEventNames.WEB_HOMEPAGE_TESTIMONY_CLICK,
                      {
                        TESTIMONY_ORDER: index + 1,
                      },
                    )
                  }}
                >
                  <h3 className={styles.nameAge}>
                    {item.name}
                    {item.age ? `, ${item.age}  Th` : ''}
                  </h3>
                  <span className={styles.timeCity}>
                    {differentDateStatus(new Date(item.bstkDate))},{' '}
                    {item?.cityName}
                  </span>
                  <div ref={descRef} className={styles.description}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(item.detail),
                      }}
                    />
                  </div>
                </CardShadow>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
      <PopupTestimony
        open={openTestimony !== null}
        testimony={openTestimony}
        onCancel={() => {
          sendAmplitudeData(AmplitudeEventName.WEB_LP_TESTIMONY_POP_UP_CLOSE, {
            Order: String(indexCard),
          })
          setOpenTestimony(null)
        }}
      />
    </>
  )
}

export default TestimonyWidget
