import React from 'react'
import styles from 'styles/components/organisms/subProduct.module.scss'
import usedCarBanner from '/public/revamp/illustration/sub-product-3.webp'
import layananSuratKendaraanBanner from '/public/revamp/illustration/sub-product-2.webp'
import fasilitasDanaBanner from '/public/revamp/illustration/sub-product-1.webp'
import Image from 'next/image'
import elementId from 'utils/helpers/trackerId'
import { sendAmplitudeData } from 'services/amplitude'
import { AmplitudeEventName } from 'services/amplitude/types'
import { IconChevronRight } from 'components/atoms/icon'
import { trackEventCountly } from 'services/countly/countly'
import { CountlyEventNames } from 'services/countly/eventNames'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import SubProductCard, { SubProductCardProps } from '../subProductCard'
import { Swiper, SwiperSlide } from 'components/atoms/swiper'

const SubProduct = () => {
  const title = ['Mobil Bekas', 'Fasilitas Dana', 'Layanan Surat Kendaraan']
  const router = useRouter()
  const onSuccessPage =
    router.pathname.includes('/landing-promo') ||
    router.pathname.includes('/paket-karyawan-astra')
  const dataSubProduct: SubProductCardProps[] = [
    {
      url: 'https://www.seva.id/mobil-bekas/c',
      promoOrder: 1,
      alt: 'mobil-bekas',
      imageSrc: usedCarBanner,
      title: 'Mobil Bekas',
      description: 'Beli mobil bekas berkualitas dengan pembiayaan dari Astra',
      textPromoBanner: 'Pelajari Lebih Lanjut',
    },
    {
      url: 'https://www.seva.id/fasilitas-dana',
      promoOrder: 2,
      alt: 'fasilitas-dana',
      imageSrc: fasilitasDanaBanner,
      title: 'Fasilitas Dana',
      description:
        'Solusi untuk kebutuhan dana langsung cair dengan jaminan BPKB mobil',
      textPromoBanner: 'Pelajari Lebih Lanjut',
    },
    {
      url: 'https://www.seva.id/layanan-surat-kendaraan',
      promoOrder: 3,
      alt: 'layanan-surat-kendaraan',
      imageSrc: layananSuratKendaraanBanner,
      title: 'Layanan Surat Kendaraan',
      description: 'Urus surat kendaraanmu dengan mudah dan nyaman',
      textPromoBanner: 'Pelajari Lebih Lanjut',
    },
  ]
  const trackCountly = (promoUrl: string, promoOrder: number) => {
    trackEventCountly(CountlyEventNames.WEB_HOMEPAGE_LAYANAN_LAIN_CLICK, {
      PAGE_DIRECTION_URL: promoUrl,
      BANNER_ORDER: promoOrder,
    })
  }

  return (
    <div data-testid={elementId.Homepage.LayananLainSeva}>
      <div className={styles.wrapperTitleSection}>
        <h2 className={styles.textHeaderSection}>Layanan Lain dari SEVA</h2>
      </div>
      <div
        // className={styles.cardInfoDetail}
        style={{ height: 'auto' }}
      >
        <div
          className={clsx({
            [styles.containerCarousel]: !onSuccessPage,
            [styles.containerCarouselSuccessPage]: onSuccessPage,
          })}
        >
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={16}
            style={{ padding: '0 16px ' }}
          >
            {dataSubProduct.map((item: SubProductCardProps, index) => (
              <SwiperSlide
                style={{
                  width: '261px',
                }}
                key={index}
              >
                <SubProductCard
                  url={item.url}
                  alt={item.alt}
                  imageSrc={item.imageSrc}
                  title={item.title}
                  description={item.description}
                  promoOrder={item.promoOrder}
                  textPromoBanner={item.textPromoBanner}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}
export default SubProduct
